<template>
  <Navigation/>
  <router-view/>
</template>

<script>
  import Navigation from '@/components/Navigation.vue'

  export default {
  components: {
    Navigation
  },
  watch: {
    '$route' (to) {
      document.title = "CBC - " + to.meta.title || 'Cloud Builder Challenge'
    }
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

body {
  font-family: 'Press Start 2P', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  color: #f6f652;
  font-size: 0.8em;
}

p {
  line-height: 140%;
}

#app {
  text-align: center;
  max-width: 80em;
  margin: 0 auto;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #fa97fa;
}

#nav a.router-link-exact-active {
  color: #9f0bda;
}
</style>
