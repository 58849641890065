/* eslint-disable */
import {CognitoAuth, StorageHelper} from 'amazon-cognito-auth-js';
import router from './router/index';
import store from './store'
import axios from 'axios';


const CLIENT_ID = "6i3it48ob0korh40lg4i7fh0hp";
const APP_DOMAIN = "cbcprotodev.auth.us-east-1.amazoncognito.com";
const REDIRECT_URI = "http://localhost:8080/auth/";
const USERPOOL_ID = "us-east-1_PeBwwKRb8us-east-1_0CMEU0Ewe";
const REDIRECT_URI_SIGNOUT = "http://localhost:8080/signout/";
const APP_URL = "dev.cloudbuilderchallenge.com";

var authData = {
    ClientId : CLIENT_ID, // Your client id here
    AppWebDomain : APP_DOMAIN,
    TokenScopesArray : ['email','openid'],
    RedirectUriSignIn : REDIRECT_URI,
    RedirectUriSignOut : REDIRECT_URI_SIGNOUT,
    UserPoolId : USERPOOL_ID,
}

var auth = new CognitoAuth(authData);
auth.userhandler = {
    onSuccess: function(result) {
        // console.log("On Success result", result);
        // UserInfoApi.getUserInfo().then(response => {
        //     router.push('/');
        // });
        
        
    },
    onFailure: function(err) {
        store.logOut();
        router.go({ path: '/error', query: { message: 'Login failed due to ' + err } });
    }
};

function getUserInfoStorageKey(){
    var keyPrefix = 'CognitoIdentityServiceProvider.' + auth.getClientId();
    var tokenUserName = auth.signInUserSession.getAccessToken().getUsername();
    var userInfoKey = keyPrefix + '.' + tokenUserName + '.userInfo';
    return userInfoKey;
}

function getUserInfo(){
    var jwtToken = auth.getSignInUserSession().getAccessToken().jwtToken;
    const USERINFO_URL = 'https://'+auth.getAppWebDomain() + '/oauth2/userInfo';
    var requestData = {
        headers: {
            'Authorization': 'Bearer '+ jwtToken
        }
    }
    return axios.get(USERINFO_URL, requestData).then(response => { 
        return response.data;
    });
}

export default{
    auth: auth,
    getSession(){
        return auth.getSession();
    },
    getUserInfoStorageKey,
    getUserInfo,

}