<template>
  <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/challenge">Challenge</router-link> |
    <router-link to="/scores">Scores</router-link>
    <span v-if="isLoggedIn"> | <router-link to="/account">My Account</router-link></span>
    <span v-if="isLoggedIn"> | <button
                                id="logout-button"
                                @click.prevent="clickLogOut"
                              >
                              Log out</button>
      </span>
    <span v-if="!isLoggedIn && siteIsActive"> | <a href="https://cbcprotodev.auth.us-east-1.amazoncognito.com/oauth2/authorize?client_id=6i3it48ob0korh40lg4i7fh0hp&response_type=code&scope=email+openid+phone&redirect_uri=http%3A%2F%2Flocalhost%3A8080%2Fauth%2F">Sign Up/Log In</a></span>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: 'Navigation',
  methods: {
    clickLogOut() {
      this.$store.dispatch('logOut').then(() => {
        this.$router.push('/')
      });
    }
  },
  props: {
    
  },
  computed: {
      ...mapGetters({
        isLoggedIn: 'isLoggedIn',
        siteIsActive: 'siteIsActive'
      })
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
li {
  list-style: outside;
  margin-bottom: 0.4em;
}
h2 {
  margin: 1em 0 0;
}
a {
  color: #42b983;
}
</style>
