<template>
  <div class="hello">
    <h1>Welcome to the Cloud Builder Challenge</h1>

    <h2>What is it?</h2>
    <p>The Cloud Builder Challenge is a game where you create an API using your cloud provider of choice, receive payloads, match and transform those payloads, and return to our server. You receive points for faster returns. Complete all payloads in 30 minutes to progress to the next wave.</p>

    <h2>Who is this challenge for?</h2>
    <p>This challenge is intended as a spiritual successor to <a href="https://cloudresumechallenge.dev">The Cloud Resume Challenge</a>. It is assumed that you either completed or you have the skills developed in that challenge. You will need them as this challenge gives you even less direction to get started. Participating in it should give you really good stories to tell in your next interview.</p>

    <h2>What will I learn?</h2>
    <p>DevOps, in the purest sense of the word. You'll be developing and operating a real-world service. When things fail, it'll be up to you to figure out why. Think about how you might learn that something didn't work.</p>

    <h2>Is it a competition?</h2>
    <p>Yes and no. The only person I want you to seriously compete against is yourself. See if you can tighten up a loop to bump your score higher. Scores are only published for friendly bragging rights. </p>

    <h2>How much will it cost?</h2>
    <p>The challenge intended to be capable within the limits of the free tiers offered by the cloud providers. While it's entirely possible to stand up massive infrastructure, you'll learn more by conforming to the cost constraints of the free tier. When operating cloud infrastucture, billing alerts are always encouraged. Unfortunately, I cannot cover any costs incurred by participating in this challenge.</p>

    <h2>Should I share my solutions?</h2>
    <p>I highly recommend you don't share your solutions, or go looking for solutions before attempting it yourself. Using code you didn't write will severely hinder your learning. Please do collaborate with like-minded individuals, though. Pairing up or forming challenge groups are great ways to learn together.</p>
  </div>
</template>

<script>

export default {
  name: 'Home'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
  margin: 0 auto;
  max-width: 55em;
}
li {
  list-style: outside;
  margin-bottom: 0.4em;
}
h2 {
  margin: 1em 0 0;
}
a {
  color: #42b983;
}
</style>