import { createStore } from 'vuex'
import auth from '../auth'


export default createStore({
  state: {
    isLoggedIn: false,
    user: {
      name: '',
    },
    siteIsActive: true
  },
  mutations: {
    setUserLoggedIn(state) {
      state.isLoggedIn = true;
    },
    setUser(state, user) {
      console.log("Setting user.");
      console.log(user);
      state.user.email = user.email;
    },
    setCredentials(state, creds) {
      state.user.accessToken = creds.accessToken;
      state.user.idToken = creds.idToken;
      state.user.refreshToken = creds.refreshToken;
    },
    setUserLoggedOut(state) {
      state.isLoggedIn = false;
      state.user = {
        name: '',
      };
    }
  },
  actions: {
    setAccessToken({commit}, token) {
      commit('setAccessToken', token);
    },
    logIn({commit}, currUrl) {
      auth.auth.parseCognitoWebResponse(currUrl);
      // commit('setCredentials', response);
      auth.getUserInfo().then(
        (res) => commit('setUser', res)
      );
      // auth.getSession().then(
      //   (res) => console.log(res)
      // );
      
      commit('setUserLoggedIn');
    },
    logOut() {
      console.log('logging out');
      this.commit('setUserLoggedOut');
    }
  },
  getters: {
    isLoggedIn: state => state.isLoggedIn,
    siteIsActive: state => state.siteIsActive
  }
})
