import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index'
// import auth from '../auth'

function requireAuth(to, from, next) {
  console.log('Checking Auth');
  if(!store.dispatch('getUserSession').isAuthenticated) {
    next({
      path: '/login',
      query: { redirect: '/'}
    })
  } else {
    next()
  }
}


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Home'
    }
  },
  {
    path: '/challenge',
    name: 'Challenge',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "challenge" */ '../views/Challenge.vue')
  },
  {
    path: '/scores',
    name: 'Scores',
    component: () => import(/* webpackChunkName: "scores" */ '../views/Scores.vue')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/auth/', beforeEnter(to, from, next) {
      var currUrl = window.location.href;
      store.dispatch('logIn', currUrl);
      next({
        path: '/',
        query: { redirect: '/'}
      })
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
